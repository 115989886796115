import { t } from 'i18next';
import { h } from 'hyperapp';
import { allRoutes, addCSRFToken } from '@app/core';
import { Link } from '@app/elements';

import { UserMenu } from '@app/layouts/usermenu';

import icoPatient from '@app/img/ico/left-menu/ico-patient.png';
import icoPatientx2 from '@app/img/ico/left-menu/ico-patient@2x.png';
import icoPatientBlue from '@app/img/ico/left-menu/ico-patient-active.png';
import icoPatientBluex2 from '@app/img/ico/left-menu/ico-patient-active@2x.png';

import icoPatients from '@app/img/ico/left-menu/ico-listing.png';
import icoPatientsx2 from '@app/img/ico/left-menu/ico-listing@2x.png';
import icoPatientsBlue from '@app/img/ico/left-menu/ico-listing-active.png';
import icoPatientsBluex2 from '@app/img/ico/left-menu/ico-listing-active@2x.png';

export default {
    initView: function() {
        let view = (props, actions) => (
            <div class='leftmenu-wrapper'>
                <ul class='mainmenu'>
                    <div class={'minifyLeftMenu ' + (props.minifiedLeftMenu ? 'minifiedLeftMenu' : '')} onclick={props.minifyLeftMenu}>
                        <p class=''></p>
                    </div>
                    <p class='thfOnglet'>{t('Patients')}</p>
                    <li onclick={() => window.location.href = addCSRFToken(allRoutes['private.patients.listing'].pathname)} class={props.page == 'patients' ? 'active' : ''}><div>{props.page == 'patients' ? <img class='activated' src={icoPatientsBlue} srcset={`${icoPatientsBluex2} 2x`} alt='' /> : <img class='activated' src={icoPatients} srcset={`${icoPatientsx2} 2x`} alt='' />}</div><Link>{t('Liste des patients')}</Link></li>
                    <li onclick={() => window.location.href = addCSRFToken(allRoutes['private.patient.add'].pathname)} class={props.page == 'patient' ? 'active' : ''}><div>{props.page == 'patient' ? <img class='activated' src={icoPatientBlue} srcset={`${icoPatientBluex2} 2x`} alt='' /> : <img class='activated' src={icoPatient} srcset={`${icoPatientx2} 2x`} alt='' />}</div><Link>{t('Ajouter un patient')}</Link></li>
                </ul>

                <UserMenu {...props} display={'connected'} minifiedLeftMenu={props.minifiedLeftMenu}></UserMenu>
            </div>
        );
        return view;
    }
}